// Generated by Framer (b0f2619)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Material } from "https://framerusercontent.com/modules/6Ldpz1V0DkD45gXvi67I/PCgBX5d6MdQT7E7nhdXn/Material.js";
const MaterialFonts = getFonts(Material);

const cycleOrder = ["R5BdxCzgr", "VveDXbL0M"];

const serializationHash = "framer-l59cA"

const variantClassNames = {R5BdxCzgr: "framer-v-1lzzzsr", VveDXbL0M: "framer-v-1azhx9u"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Menu Open": "VveDXbL0M", Menu: "R5BdxCzgr"}

const getProps = ({height, id, tap, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "R5BdxCzgr", wPt4EbSz7: tap ?? props.wPt4EbSz7} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, wPt4EbSz7, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "R5BdxCzgr", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1bkcing = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (wPt4EbSz7) {
const res = await wPt4EbSz7(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1lzzzsr", className, classNames)} data-framer-name={"Menu"} data-highlight initial={variant} layoutDependency={layoutDependency} layoutId={"R5BdxCzgr"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={onTap1bkcing} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({VveDXbL0M: {"data-framer-name": "Menu Open"}}, baseVariant, gestureVariant)}><motion.div className={"framer-10661a9-container"} layoutDependency={layoutDependency} layoutId={"xpVPNs3hw-container"}><Material color={"var(--token-bb5a1b5b-5444-416e-944e-7906200c0221, rgb(136, 94, 168)) /* {\"name\":\"Purple\"} */"} height={"100%"} iconSearch={"Home"} iconSelection={"Menu"} iconStyle15={"Filled"} iconStyle2={"Filled"} iconStyle7={"Filled"} id={"xpVPNs3hw"} layoutId={"xpVPNs3hw"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} width={"100%"} {...addPropertyOverrides({VveDXbL0M: {iconSelection: "Close"}}, baseVariant, gestureVariant)}/></motion.div></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-l59cA[data-border=\"true\"]::after, .framer-l59cA [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-l59cA.framer-1r0z8wd, .framer-l59cA .framer-1r0z8wd { display: block; }", ".framer-l59cA.framer-1lzzzsr { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: auto; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: auto; }", ".framer-l59cA .framer-10661a9-container { flex: none; height: 30px; position: relative; width: 30px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-l59cA.framer-1lzzzsr { gap: 0px; } .framer-l59cA.framer-1lzzzsr > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-l59cA.framer-1lzzzsr > :first-child { margin-left: 0px; } .framer-l59cA.framer-1lzzzsr > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 30
 * @framerIntrinsicWidth 30
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"VveDXbL0M":{"layout":["auto","auto"]}}}
 * @framerVariables {"wPt4EbSz7":"tap"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerdwiiisVnB: React.ComponentType<Props> = withCSS(Component, css, "framer-l59cA") as typeof Component;
export default FramerdwiiisVnB;

FramerdwiiisVnB.displayName = "Menu Drop Down";

FramerdwiiisVnB.defaultProps = {height: 30, width: 30};

addPropertyControls(FramerdwiiisVnB, {variant: {options: ["R5BdxCzgr", "VveDXbL0M"], optionTitles: ["Menu", "Menu Open"], title: "Variant", type: ControlType.Enum}, wPt4EbSz7: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerdwiiisVnB, [...MaterialFonts])